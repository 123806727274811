
import { Options, Vue } from 'vue-class-component';
import { InjectReactive } from 'vue-property-decorator';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import CartItem from '@/modules/order/common/components/cart-item.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Item } from '@/common/models/Item.model';
import GiftCardPayment from '../gift-card-payment.component.vue';
import CartPromo from '@/modules/order/details/components/cart-promo.component.vue';

@Options({
	name: 'OrderSummaryInfoBox',
	components: { CartItem, GiftCardPayment, CartPromo },
	emits: ['onPayNow'],
})
export default class OrderSummaryInfoBox extends Vue {
	@InjectReactive() activeOrder!: ActiveOrder;
	openGiftCard = false;
	get isLoading(): boolean {
		return orderModule.isCheckoutLoading || this.activeOrder.isLoading;
	}
	get balanceDue(): number {
		return this.activeOrder.balance;
	}

	get feesTotal(): number {
		return this.activeOrder.fees.reduce((p, c) => (p += c.Amount), 0);
	}

	get items(): Item[] {
		return this.activeOrder.items;
	}

	get isNew(): boolean {
		return this.activeOrder.isNew;
	}

	get orderStatus(): string {
		return this.isNew ? '' : this.balanceDue > 0 ? 'unpaid' : this.balanceDue < 0 ? 'overpaid' : 'paid';
	}

	get paymentTotal(): number {
		return this.activeOrder.paidPayments.reduce((p, c) => (p += c.PaymentAmount), 0);
	}
}
