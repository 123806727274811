
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Field, ErrorMessage } from 'vee-validate';

@Options({
	name: 'RadioButtonGroup',
	components: { Field, ErrorMessage },
	emits: ['update:modelValue', 'onUpdate'],
})
export default class RadioButtonGroup extends Vue {
	@Prop({ default: [] }) options!: { label: string; value: unknown }[];
	@Prop() modelValue!: unknown;
	@Prop() name!: string;
	@Prop({ default: 'This field is invalid' }) error!: string;
	@Prop({ default: true }) selectionRequired!: boolean;

	isValid = false;

	onChange(val: unknown) {
		this.$emit('update:modelValue', val);
	}

	validate() {
		this.isValid = this.selectionRequired ? (this.modelValue as boolean) : true;
		return this.isValid;
	}

	@Watch('modelValue') valueWatcher() {
		if (this.selectionRequired) this.validate();
		else this.isValid = true;
	}
}
