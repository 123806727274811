
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Icons, iconLibrary } from '@/common/utilities/iconsLibrary.utility';

@Options({
	name: 'Chip',
	components: {},
	emits: ['onClose'],
})
export default class Chip extends Vue {
	@Prop({ default: '' }) label!: string;
	@Prop({ default: '#e6e6e6' }) color!: string;
	@Prop({ default: '' }) title!: string;
	@Prop({ default: false }) animateOnClose!: boolean;

	handleClose() {
		if (this.animateOnClose) {
			this.$el.classList.add('anim-close');
			setTimeout(() => {
				this.$el.classList.remove('anim-close');
			}, 10000);
		}
		this.$emit('onClose');
	}

	closeBtn = iconLibrary.getIcon(Icons.CLOSE_BUTTON_1, 18, 18, 2);
}
