
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import CountrySelectInput from '@/common/components/form-ui/country-select-input.component.vue';
import { isRequired } from '@/common/utilities/Validators.utility';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { clientModule } from '@/modules/client/client.vuex-module';

@Options({
	name: 'PhoneNumberInput',
	components: { Field, Form, ErrorMessage, CountrySelectInput },
	emits: ['onValidPhone', 'update:modelValue', 'onChange'],
})
export default class PhoneNumberInput extends Vue {
	@Prop() modelValue!: { country: number; area: number; subscriber: number };
	isRequired = isRequired; // validator
	localPhone = { ...this.modelValue };
	@Watch('localPhone', { deep: true, immediate: true })
	onStoreChange(newValue: any) {
		newValue.country = +newValue.country;
		this.localPhone = newValue;
		this.$emit('update:modelValue', this.localPhone);
		this.$emit('onChange', this.localPhone);
	}
	get customerPhone() {
		return this.localPhone;
	}
	// set customerPhone(newValue: any) {
	// 	this.modelValue = newValue;
	// }
	validPhoneNumber() {
		if (!this.customerPhone.area || !this.customerPhone.subscriber) return false;
		const phone = `${this.customerPhone.area}${this.customerPhone.subscriber}`;
		const isValid = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone);

		if (isValid) this.$emit('onValidPhone', this.customerPhone);
		return isValid;
	}

	onAreaInput() {
		if (this.customerPhone.area.toString().length == 2) {
			console.log('SKIPPIN!');
			document.getElementById('subNum')!.focus();
		}
	}
}
