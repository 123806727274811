
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'CountBadge',
})
export default class CountBadge extends Vue {
	@Prop({ default: 0 }) count!: number;
}
