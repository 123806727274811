
import { Options, Vue } from 'vue-class-component';
import { BusEvent } from '../enums/BusEvents.enum';
import { eventBus } from '@/proxies/event-bus.proxy';
import { defineAsyncComponent } from 'vue';
import ErrorAlert from './alert-modals/error-alert.component.vue';

// const IssueRefundAlert = defineAsyncComponent(() => import(/* webpackChunkName: "order" */ './alert-modals/issue-refund-alert.component.vue'));
// const PaymentSuccessAlert = defineAsyncComponent(() => import(/* webpackChunkName: "order" */ './alert-modals/payment-success-alert.component.vue'));
// const RefundSuccessAlert = defineAsyncComponent(() => import(/* webpackChunkName: "order" */ './alert-modals/refund-success-alert.component.vue'));
// const LoseChangesAlert = defineAsyncComponent(() => import(/* webpackChunkName: "order" */ './alert-modals/lose-changes-alert.component.vue'));
// const SelectActivityAlert = defineAsyncComponent(() => import(/* webpackChunkName: "order" */ './alert-modals/select-activity-alert.component.vue'));
// const CancelSuccessAlert = defineAsyncComponent(() => import(/* webpackChunkName: "quick-edit" */ './alert-modals/cancel-success-alert.component.vue'));
// const SavingActivityAlert = defineAsyncComponent(() => import(/* webpackChunkName: "quick-edit" */ './alert-modals/saving-activity-alert.component.vue'));
const PolicyAlert = defineAsyncComponent(() => import(/* webpackChunkName: 'order' */ './alert-modals/policy-alert.component.vue'));

type AlertRequest = {
	name: string;
	data?: any;
};

@Options({
	name: 'AlertModal',
	// components: { IssueRefundAlert, PaymentSuccessAlert, ErrorAlert, LoseChangesAlert, RefundSuccessAlert, CancelSuccessAlert, SelectActivityAlert, SavingActivityAlert, PolicyAlert },
	components: { ErrorAlert, PolicyAlert },
})
export default class AlertModal extends Vue {
	request: { name: string; data?: any } | null = null;

	created() {
		eventBus.on(BusEvent.ALERT_REQUEST, (req) => {
			this.request = req as AlertRequest;
		});
	}
}
