
import { OrderPromoCode } from '@/common/models/OrderPromoCode.model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'PromoCode',
	emits: ['deletePromo'],
})
export default class PromoCode extends Vue {
	@Prop() promo!: OrderPromoCode;
}
