
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Field, ErrorMessage } from 'vee-validate';
import Checkbox from './checkbox.component.vue';
import { BusEvent } from '@/common/enums/BusEvents.enum';
import { eventBus } from '@/proxies/event-bus.proxy';

@Options({
	name: 'RadioButtonGroup',
	components: { Field, ErrorMessage, Checkbox },
	emits: ['update:modelValue', 'open'],
})
export default class RadioButtonGroup extends Vue {
	@Prop() modelValue!: boolean;
	@Prop() name!: string;
	@Prop({ default: 'This field is required' }) error!: string;
	@Prop({ default: true }) isRequired!: boolean;
	@Prop({ default: '' }) policyTerms!: string;
	@Prop() options!: { label: string; value: boolean }[];

	isValid = false;

	onChange(val: unknown) {
		if (val) {
			this.onOpenPolicy();
		} else {
			this.$emit('update:modelValue', val);
		}
		this.validate();
	}

	validate() {
		this.isValid = this.isRequired ? (this.modelValue as boolean) : true;
		return this.isValid;
	}

	onOpenPolicy() {
		eventBus.emit(BusEvent.ALERT_REQUEST, {
			name: 'PolicyAlert',
			data: {
				name: this.name,
				policy: this.policyTerms,
				options: this.options,
				onAgree: (val: boolean) => this.$emit('update:modelValue', val),
			},
		});
	}

	@Watch('modelValue') valueWatcher() {
		if (this.isRequired) this.validate();
		else this.isValid = true;
	}
}
