
import { Options, Vue } from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import InfoBox from './info-box.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';

@Options({
	name: 'PrePaymentInfoBox',
	components: { InfoBox },
	emits: ['onPayment'],
})
export default class PrePaymentInfoBox extends Vue {
	@Prop({ default: true }) isActive!: boolean;
	@Prop({ default: false }) appMode!: boolean;
	@Prop({ default: false }) retry!: boolean;

	@InjectReactive() activeOrder!: ActiveOrder;
	paymentOption: 'now' | 'later' = 'now';
	get isLoading(): boolean {
		return orderModule.isCheckoutLoading || this.activeOrder.isLoading;
	}
	onNext() {
		// if (this.activeOrder.paymentDue) {
		// 	this.$router.push({ name: this.activeOrder.isNew ? 'OrderPaymentView' : 'OrderPaymentView' });
		// } else {
		// 	// no balance due
		// }
		//Changed by caleb to hook into readerSDK
		this.$emit('onPayment');
	}
}
