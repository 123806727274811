
import { DiscountType } from '@/common/enums/PaymentType.enum';
import { Status } from '@/common/enums/status.enum';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'SummaryItem',
	components: {},
})
export default class SummaryItem extends Vue {
	@Prop() item!: Item;

	get isSaved() {
		return !orderModule._activeOrder?.isNew;
	}

	get itemActivities() {
		return this.item.Activities;
	}

	// maps activity items into display data
	get activityData() {
		return this.item.Activities.map((a) => {
			const data = {
				name: a.Name,
				subtotal: a.SubTotal,
				selectedTimes: a.SelectedTimes.map((t) => {
					return {
						status: Status[t.Appointment.Status],
						timeStr: a.AppointmentTypeKey == 2 ? `${a.AppointmentDayOfTheWeek}, ${a.AppointmentMonth} ${a.AppointmentDay}` : `${a.AppointmentDayOfTheWeek}, ${a.AppointmentMonth} ${a.AppointmentDay} at ${t.TimeString}`,
						rates: t.Rates.filter((r) => r.Participants > 0).map((r) => {
							return {
								name: r.Name,
								price: r.Price,
								quantity: r.Participants,
								subtotal: r.Price * r.Participants,
								discounts: t.Discounts.filter((d) => d.Rates.some((dr) => dr.OrderEntityTimeRateGUID == r.OrderEntityTimeRateGUID)).map((d) => {
									const rateDiscount = d.Rates.find((dr) => dr.OrderEntityTimeRateGUID == r.OrderEntityTimeRateGUID);
									return {
										name: d.DiscountName
											? `${d.DiscountName} x ${rateDiscount!.Participants}`
											: d.DiscountTypeKey == DiscountType.AMOUNT
											? `$${d.Amount} off x ${rateDiscount!.Participants}`
											: `${d.Percentage}% off x ${rateDiscount!.Participants}`,
										subtotal:
											d.DiscountTypeKey == DiscountType.PREDEFINED
												? (d.DiscountTotal / t.Participants) * rateDiscount!.Participants
												: d.DiscountTypeKey == DiscountType.AMOUNT
												? (d?.Amount ?? 0) * rateDiscount!.Participants
												: ((d?.Percentage ?? 0) / 100) * rateDiscount!.Price * rateDiscount!.Participants,
									};
								}),
							};
						}),
						addons: t.AddOns.filter((add) => add.Quantity > 0).map((add) => {
							return {
								name: add.AddOnName,
								quantity: add.Quantity,
								price: add.PublishedPrice,
								subtotal: add.PublishedPrice * add.Quantity,
								isTip: add.IsTip,
								variants: add.Variants.map((v) => {
									return {
										name: v.ProductName,
										quantity: v.Quantity,
										price: v.PublishedPrice,
										subtotal: v.PublishedPrice * v.Quantity,
										isTip: v.IsTip,
									};
								}),
							};
						}),
					};
				}),
			};

			data.subtotal += data.selectedTimes.reduce((p, c) => c.addons.reduce((q, d) => (q += d.subtotal), 0), 0); // add addons

			return data;
		});
	}

	get itemSubtotal() {
		return this.activityData.reduce((p, c) => (p += c.subtotal), 0);
	}

	onEdit() {
		orderModule.editItem(JSON.parse(JSON.stringify(this.item)));

		this.$router.push({
			name: 'AddExperienceView',
			params: {
				activityId: 'edit',
				catalog: this.item.PackageKey ? 'packages' : 'activities',
			},
			query: { ...this.$route.query },
		});
	}
}
