
// import { BusEvent } from '@/common/enums/BusEvents.enum';
import { OrderPromoCode } from '@/common/models/OrderPromoCode.model';
// import { eventBus } from '@/proxies/event-bus.proxy';
import { Options, Vue } from 'vue-class-component';
import { InjectReactive } from 'vue-property-decorator';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import CountBadge from '@/common/components/count-badge.component.vue';
import Chip from '@/common/components/si-chip.component.vue';
import SmallLoader from '@/common/components/small-loader.component.vue';

@Options({
	name: 'CartPromo',
	components: { Chip, CountBadge, SmallLoader },
})
export default class CartPromo extends Vue {
	@InjectReactive() activeOrder!: ActiveOrder;

	expandPromo = false;
	promoCode = '';
	appliedStatus: string[] = [];

	applyingPromo = false;

	get appliedPromos() {
		return this.activeOrder?.orderPromos ?? [];
	}

	togglePromoField() {
		this.expandPromo = !this.expandPromo;
		if (this.expandPromo) (this.$refs.promoInput as HTMLInputElement).focus();
	}

	onFocus() {
		// eventBus.emit(BusEvent.DISABLE_SEARCH);
	}

	onBlur() {
		// eventBus.emit(BusEvent.ENABLE_SEARCH);
	}

	async applyPromo() {
		if (!this.promoCode || !this.activeOrder) return;
		this.applyingPromo = true;
		await this.activeOrder
			?.addBookPromo(this.promoCode)
			.then((res) => {
				console.log(res);
				if (res.applied) this.promoCode = '';
				this.appliedStatus = res.message ?? [];
			})
			.finally(() => (this.applyingPromo = false));
	}

	async onDelete(promo: OrderPromoCode) {
		this.activeOrder.deleteBookPromo(promo);
	}
}
