
import { clientModule } from '@/modules/client/client.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Country } from '@/common/models/ClientLocation.model';

@Options({
	name: 'CountrySelectInput',
	emits: ['update:modelValue', 'onChange'],
})
export default class CountrySelectInput extends Vue {
	@Prop() modelValue!: number | null;
	@Prop({ default: true }) sayPlease!: boolean;
	@Prop({ default: 'Select' }) defaultOptionLabel!: string;

	get countries(): { label: string; value: unknown }[] {
		return (
			clientModule.location?.Countries.map((c) => {
				return {
					label: `${c.Name} (+${c.E164})`,
					value: c.CountryKey,
				};
			}) ?? []
		);
	}

	get countryCodes(): Country[] {
		return clientModule.location?.Countries ?? [];
	}
	get countryFlag() {
		var find = this.countryCodes.find((ele) => ele.CountryKey == this.modelValue);
		if (!find) {
			return false;
		}
		return 'https://book.singenuity.com/flags-master/flags/flags-iso/flat/64/' + find.CountryCode + '.png';
	}
	update(value: string) {
		console.log('UPDATING', value);
		this.$emit('update:modelValue', value);
		this.$emit('onChange', value);
	}
}
