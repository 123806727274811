
import { Options, Vue } from 'vue-class-component';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Prop } from 'vue-property-decorator';
import Checkbox from '@/common/components/form-ui/checkbox.component.vue';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';

@Options({
	name: 'EmailOptIn',
	components: { Checkbox },
})
export default class EmailOptIn extends Vue {
	// @Prop({ default: true }) isActive!: boolean;
	// @Prop({ default: true }) canEdit!: boolean;

	get agreed(): boolean {
		return orderModule._activeOrder?.agreed ?? false;
	}
	set agreed(newValue: boolean) {
		if (orderModule._activeOrder) orderModule._activeOrder.agreed = newValue;
	}
	get activeOrder(): ActiveOrder | null {
		return orderModule._activeOrder;
	}
	get requirements() {
		return this.activeOrder?.requirements;
	}
	toggle() {
		if (this.activeOrder) this.activeOrder.customerInfo.EmailOptIn = !this.activeOrder.customerInfo.EmailOptIn;
	}
}
